import {useEffect, useState} from 'react'

interface Props {
  initialPage?: number
  totalPages: number
  onPageChange?: (page: number) => void
}

interface PaginationItem {
    index?: number
}

export function Pagination({initialPage, onPageChange, totalPages}: Props) {
  const totalPageParsed = Math.ceil(totalPages)
  const [currentPage, setCurrentPage] = useState(initialPage || 1)
  const [pageList, setPageList] = useState<any>([])

  const pages = Array.from({length: totalPageParsed}, (_, index) => index + 1)

    useEffect(() => {
        if (pages?.length > 0) {

            if (pages?.length < 3) {
                setPageList(pages);
            } else if (pages?.length > 3) {
                setPageList([]);

                const firstItem = pages?.[0]
                const lastItem = pages?.[pages?.length-1];
                const separator = "...";

                let list = null;

                // beklenti: 5'in aktif olduğu senaryoda [1, "...", 4, 5, 6, "...", 19];
                if (currentPage !== firstItem
                    && currentPage - 1 !== firstItem
                    && currentPage - 2 !== firstItem
                    && currentPage !== lastItem
                    && currentPage + 1 !== lastItem
                    && currentPage + 2 !== lastItem) {
                    // ilk 3 ve son 3 sayfa dışındakiler için
                    list = [firstItem, separator, ...pages?.slice(currentPage-2, currentPage+1), separator, lastItem];
                } else {
                    // ilk 3 ve son 3 sayfa için

                    // ilk 3
                    if (currentPage === firstItem) { // 1. sayfa
                        list = [...pages?.slice(currentPage-1, currentPage+1), separator, lastItem];
                    } else if (currentPage - 1 === firstItem) {  // 2. sayfa
                        list = [...pages?.slice(currentPage-2, currentPage+1), separator, lastItem];
                    } else if (currentPage - 2 === firstItem) {  // 3. sayfa
                        list = [firstItem, ...pages?.slice(currentPage-2, currentPage+1), separator, lastItem];
                    }

                    // son 3
                    if (currentPage === lastItem || currentPage + 1 === lastItem) { // son sayfa / son sayfa-1
                        list = [firstItem, separator, ...pages?.slice(currentPage-2, currentPage+1)];
                    } else if (currentPage + 2 === lastItem) { // son sayfa-2
                        list = [firstItem, separator, ...pages?.slice(currentPage-2, currentPage+1), lastItem];
                    }

                }

                if (list === null) {
                    setPageList(pages);
                } else {
                    setPageList(list);
                }

            }
        }
    }, [currentPage]);

  return (
    <ul className='pagination pt-4'>
      <li className='page-item previous'>
        <button
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1)
              onPageChange?.(currentPage - 1)
            }
          }}
          className='page-link'
        >
          <i className='previous'></i>
        </button>
      </li>


        {pageList.map((page:number) => {

            const pageKey = typeof page === "string" ? Math.random() : page;

            return <li key={`page-${pageKey}`} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                <button
                    onClick={() => {
                        if (typeof page === "string") {
                            return;
                        }
                        setCurrentPage(page)
                        onPageChange?.(page)
                    }}
                    className='page-link'
                >
                    {page}
                </button>
            </li>

        })}

      <li className='page-item next'>
        <button
          onClick={() => {
            if (currentPage < totalPageParsed) {
              setCurrentPage(currentPage + 1)
              return onPageChange?.(currentPage + 1)
            }
            return
          }}
          className='page-link disabled:cursor-not-allowed disabled:hover:bg-transparent'
          disabled={currentPage === totalPageParsed}
        >
          <i className='next'></i>
        </button>
      </li>
    </ul>
  )
}
