import {useQueries} from 'react-query'
import {useRef, useState} from 'react'
import {QRCodeSVG} from 'qrcode.react'
import {toJpeg} from 'html-to-image'
import CopyToClipboard from 'react-copy-to-clipboard'
import {toast} from 'react-toastify'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {toAbsoluteUrl} from '_metronic/helpers'
import {ReferralButton} from './components/ReferralButton'
import {DateInput} from '_metronic/layout/components/Forms/DateInput'
import {
  DayFilter,
  InvitationType,
  getInvitationList,
  getReferralCode,
  getReferralSummary,
} from '_metronic/layout/core/requests'
import {Pagination} from "../../../_metronic/layout/components/Pagination";
import {SortOpts} from "../setup/core/_requests";
import {useNavigate} from "react-router-dom";
import {Loading} from "../apps/user-management/users-list/components/loading/Loading";

const columnHelper = createColumnHelper<InvitationType>()

const timelines = [
  {
    title: 'All',
    value: 'all',
  },
  /*{
    title: '7 Day',
    value: 'last7',
  },*/
  {
    title: 'This Month',
    value: 'last30',
  },
] as const

const columns = [
  columnHelper.accessor('start_date', {
    header: 'Register Date',
    cell: ({cell}) => new Date(cell.getValue()).toDateString(),
  }),
  {
    header: 'Commission(USD)',
    accessorKey: 'amount',
  },
  {
    header: 'Product Name',
    accessorKey: 'product_name',
  },
  columnHelper.accessor(row => row.is_paid, {
    header: 'Status',
    cell: ({cell}) => {
      const cellData = cell?.row?.original;
      if (cellData?.is_paid === true) {
        return "Paid";
      } else if (cellData?.is_paid === false && cellData?.is_cancelled !== true) {
        return "Pending";
      } else if (cellData?.is_cancelled === true) {
        return "Cancelled";
      }
    },
  }),
  columnHelper.accessor('trader_earn_date', {
    header: 'Paid Date',
    cell: ({cell}) => new Date(cell.getValue()).toDateString(),
  }),
  {
    header: 'Installments',
    accessorKey: 'installments',
  },
]

interface IDateRange {
  startDate?: Date
  endDate?: Date
  key: string
}

type QueryParams = { page: number, limit: number, order: SortOpts }

export function Referral() {
  const navigate = useNavigate()

  const [selectedTime, setSelectedTime] = useState<DayFilter>('all')
  const [selectRange, setSelectRange] = useState<IDateRange>({
    startDate: new Date('2023-05-01'),
    endDate: undefined,
    key: 'selection',
  })
  const qrRef = useRef(null)

  const currPage = Number(new URLSearchParams(window.location.search).get('page')) || 1

  const handleDateSelect = (ranges: {selection: IDateRange}) => {
    setSelectRange(ranges.selection)
  }

  const getQueryString = (params: QueryParams): string => {
    const search = new URLSearchParams();
    if (params.page !== 1)
      search.append("page", params.page.toString());
    if (params.limit !== 10)
      search.append("limit", params.limit.toString());
    if (params.order !== "newest")
      search.append("order", params.order);
    return search.toString();
  }

  const getQueryParams = (): QueryParams => {
    const search = new URLSearchParams(window.location.search);
    return {
      page: Number(search.get("page")) || 1,
      limit: Number(search.get("limit")) || 10,
      order: search.get("order") as SortOpts || "newest"
    };
  }

  const downloadQRCode = () => {
    if (qrRef.current) {
      toJpeg(qrRef.current, {cacheBust: true})
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'QRCode.jpeg'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          toast.error('Failed to download QRCode')
        })
    }
  }

  const allQueries = useQueries([
    {
      queryKey: 'referral-code',
      queryFn: async () => (await getReferralCode()).data.code,
    },
    {
      queryKey: ['referral-summary', selectedTime],
      queryFn: async () => (await getReferralSummary(selectedTime)).data,
    },
    {
      queryKey: ['invitation-list', selectRange],
      queryFn: async () => {
        const params = getQueryParams();
        const startDateParam = selectRange.startDate
          ? new Date(selectRange.startDate).toISOString().split('T')[0]
          : new Date('1980-01-01').toISOString().split('T')[0]
        const endDateParam = selectRange.endDate
          ? new Date(selectRange.endDate).toISOString().split('T')[0]
          : undefined

        return (
          await getInvitationList({
            from_date: startDateParam,
            to_date: endDateParam,
            limit: params?.limit,
            skip: (params.page - 1) * params.limit,
            order_by: params.order,
          })
        ).data
      },
    },
  ])

  const referralCode = allQueries[0].data

  const referralSummary = allQueries[1].data

  const invitationList = allQueries[2];


  const {getHeaderGroups, getRowModel} = useReactTable<InvitationType>({
    data: invitationList?.data?.data || [],
    columns,
    sortDescFirst: true,
    getCoreRowModel: getCoreRowModel<InvitationType>(),
    getSortedRowModel: getSortedRowModel<InvitationType>(),
  })

  const handlePageChange = (page: number) => {
    const params = getQueryParams();
    params.page = Number(page);
    const search = getQueryString(params);
    navigate({
      pathname: '/referral',
      search,
    })
    invitationList.refetch();
  }

  return (
    <div className='px-3 pb-10 flex gap-12 flex-col justify-center'>
      <div className='grid gap-y-8 grid-cols-1 lg:grid-cols-2 justify-center items-center'>
        <div className='order-2 lg:!order-1 flex gap-6 flex-col items-center lg:items-start'>
          <h1 className='font-bold text-4xl text-mydark dark:text-white text-center lg:!text-left relative'>
            Refer & Earn: <br />
            Join Our Social Trading Community
          </h1>

          <p className='text-lg leading-7 font-medium text-mygray-400 dark:text-white text-center lg:!text-left relative'>
            Join our analyst community to get monthly fee. Refer us to your follower via your
            reference code and get monthly payment, besides you will get monthly additional payment
            regarding your succession.
          </p>
        </div>
        <div className='order-1 lg:!order-2 flex justify-center lg:justify-end'>
          <img
            className='w-[400px]'
            src='https://bottomup-setup.s3.us-west-2.amazonaws.com/refer-bottomup.svg'
          />
        </div>
      </div>

      {/* Invitation Code */}
      <div
        className='p-8 bg-white dark:!bg-mydarkbg
        grid gap-5 grid-cols-1 lg:grid-cols-3 text-mygray-400 dark:text-white
        rounded-xl'
      >
        <div className='flex flex-col gap-2'>
          <p className='text-lg font-medium '>Referral Code</p>

          <div className='relative py-3 pl-4 text-lg border border-gray-300 rounded-lg overflow-hidden'>
            <p className='w-full overflow-y-hidden overflow-x-auto scrollbar-hide whitespace-nowrap select-none'>
              {referralCode}
            </p>
            <div className='px-2 py-3 bg-white dark:!bg-mydarkbg absolute top-1/2 -translate-y-1/2 right-0'>
              <CopyToClipboard
                text={referralCode || ''}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <button type='button' className='p-2 bg-mygray-200 rounded-full'>
                  <svg
                    width='16'
                    height='17'
                    viewBox='0 0 16 20'
                    fill='none'
                    xmlns='https://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 16C5.45 16 4.979 15.804 4.587 15.412C4.195 15.02 3.99934 14.5493 4 14V2C4 1.45 4.196 0.979002 4.588 0.587002C4.98 0.195002 5.45067 -0.000664969 6 1.69779e-06H15C15.55 1.69779e-06 16.021 0.196002 16.413 0.588002C16.805 0.980002 17.0007 1.45067 17 2V14C17 14.55 16.804 15.021 16.412 15.413C16.02 15.805 15.5493 16.0007 15 16H6ZM6 14H15V2H6V14ZM2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V5C1.69779e-06 4.71667 0.0960018 4.479 0.288002 4.287C0.480002 4.095 0.717335 3.99934 1 4C1.28334 4 1.521 4.096 1.713 4.288C1.905 4.48 2.00067 4.71734 2 5V18H12C12.2833 18 12.521 18.096 12.713 18.288C12.905 18.48 13.0007 18.7173 13 19C13 19.2833 12.904 19.521 12.712 19.713C12.52 19.905 12.2827 20.0007 12 20H2Z'
                      className='fill-mygray-400'
                    />
                  </svg>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='text-lg font-medium'>Link</p>

          <div className='relative py-3 pl-4 text-lg dark:text-white border border-gray-300 rounded-lg overflow-hidden'>
            <p className='text-inherit w-full overflow-y-hidden overflow-x-auto scrollbar-hide whitespace-nowrap'>
              {`https://www.bottomup.app/analysts/${referralCode}`}
            </p>
            <div className='px-2 py-3 bg-white dark:!bg-mydarkbg absolute top-1/2 -translate-y-1/2 right-0'>
              <CopyToClipboard
                text={`https://www.bottomup.app/analysts/${referralCode}`}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <button type='button' className='p-2 bg-mygray-200 rounded-full'>
                  <svg
                    width='16'
                    height='17'
                    viewBox='0 0 16 20'
                    fill='none'
                    xmlns='https://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 16C5.45 16 4.979 15.804 4.587 15.412C4.195 15.02 3.99934 14.5493 4 14V2C4 1.45 4.196 0.979002 4.588 0.587002C4.98 0.195002 5.45067 -0.000664969 6 1.69779e-06H15C15.55 1.69779e-06 16.021 0.196002 16.413 0.588002C16.805 0.980002 17.0007 1.45067 17 2V14C17 14.55 16.804 15.021 16.412 15.413C16.02 15.805 15.5493 16.0007 15 16H6ZM6 14H15V2H6V14ZM2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V5C1.69779e-06 4.71667 0.0960018 4.479 0.288002 4.287C0.480002 4.095 0.717335 3.99934 1 4C1.28334 4 1.521 4.096 1.713 4.288C1.905 4.48 2.00067 4.71734 2 5V18H12C12.2833 18 12.521 18.096 12.713 18.288C12.905 18.48 13.0007 18.7173 13 19C13 19.2833 12.904 19.521 12.712 19.713C12.52 19.905 12.2827 20.0007 12 20H2Z'
                      className='fill-mygray-400'
                    />
                  </svg>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className='relative flex flex-col gap-2'>
          <p className='text-lg font-medium'>Download QR code</p>

          <ReferralButton
            onClick={downloadQRCode}
            className='peer text-white text-center text-[14px] font-bold bg-gradient-to-r from-[#2ce3dd] to-[#67a9ff] hover:from-[#4cffdf] hover:to-[#75adf6]'
          >
            Download
          </ReferralButton>

          <div className='hidden peer-hover:block hover:block max-w-sm absolute left-1/2 -translate-x-1/2 bottom-28 w-full p-3 bg-white rounded-lg shadow-lg'>
            <div
              ref={qrRef}
              className='py-7 text-center flex flex-col items-center gap-4 bg-slate-100 rounded-md'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/horizontalFullLogo.svg')}
                className='h-70px'
              />
              <QRCodeSVG value={`https://www.bottomup.app/analysts/${referralCode}`} />
              <div>
                <p className='font-bold text-gray-600'>Referral Code</p>
                <h2 className='font-bold text-2xl text-mydark'>{referralCode}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dashboard */}
      <div>
        <h1 className='mb-7 font-bold text-4xl'>Dashboard</h1>

        <div className='flex flex-col sm:flex-row justify-between'>
          <ul className='mb-5 flex gap-3 items-center'>
            {timelines.map((item, index) => (
              <li key={`${index}.!-time`} className='text-xl font-medium'>
                <button
                  type='button'
                  className={selectedTime === item.value ? 'text-mypurple' : 'text-mygray-300'}
                  onClick={() => setSelectedTime(item.value)}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className='p-8 bg-white dark:!bg-mydarkbg grid gap-5 lg:!gap-0 grid-cols-1 lg:grid-cols-2 rounded-xl'>
          <div>
            <p className='mb-1 text-lg text-mygray-300 dark:text-white font-medium'>
              Your Earnings
            </p>
            <h3 className='flex gap-1 text-3xl font-medium text-mydark dark:text-white'>
              {referralSummary && !isNaN(referralSummary?.total_amount)
                ? referralSummary?.total_amount
                : '-'}{' '}
              <span className='text-lg'>USD</span>
            </h3>
          </div>
          <div>
            <p className='mb-1 text-lg text-mygray-300 dark:text-white font-medium'>
              Qualified Referrals
            </p>
            <h3 className='flex gap-1 text-3xl font-medium text-mydark dark:text-white'>
              {referralSummary && !isNaN(referralSummary?.total_user)
                ? referralSummary?.total_user
                : '-'}{' '}
              <span className='text-lg'>users</span>
            </h3>
          </div>
        </div>
      </div>

      {/* Invitation Table */}
      <h1 className='font-bold text-4xl'>My Invitations</h1>
      <DateInput
        containerClass='self-start'
        ranges={selectRange}
        onChange={handleDateSelect as never}
      />
      <div>

        <div className="relative">
          {(invitationList?.isLoading || invitationList?.isRefetching) && <div className="absolute w-full h-full z-10 bg-white/50 d-flex align-items-center justify-content-center">
            <div className="bg-white w-[140px] h-[140px] p-5 rounded-lg border border-sky-500">
              <Loading className='text-md font-medium text-sky-500' imgClass='w-[110px] h-[75px]' />
            </div>
          </div>}

          <table className='w-full py-5 relative  bg-white dark:!bg-mydarkbg rounded-3xl overflow-y-hidden'>
            <thead className='border-b-[0.5px] dark:!border-gray-100/50'>
            {getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                      <th key={header.id} className='px-3 py-5'>
                        <p className='font-medium text-mygray-300 dark:text-white whitespace-nowrap'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </p>
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            <tbody className='relative w-full h-full overflow-y-auto'>
            {!!getRowModel().rows.length ? (
                getRowModel().rows.map((row) => (
                    <tr key={row.id} className='items-center'>
                      {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className='px-3 py-5'>
                            <p className='text-lg font-medium text-mydark dark:text-white whitespace-nowrap'>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </p>
                          </td>
                      ))}
                    </tr>
                ))
            ) : (
                <>
                  <tr>
                    <td className='!p-32' />
                    <td className='absolute top-0 left-1/2 -translate-x-1/2 p-5 h-full grid place-content-center text-2xl text-mygray-300 dark:text-white font-medium'>
                      No Invitations
                    </td>
                  </tr>
                </>
            )}
            </tbody>
          </table>
        </div>

        <div>
          {!!invitationList.data?.total_count && invitationList.data?.total_count > 0 && (
              <Pagination
                  initialPage={currPage}
                  totalPages={(invitationList.data.total_count - 1) / invitationList.data.limit}
                  onPageChange={handlePageChange}
              />
          )}
        </div>

      </div>

    </div>
  )
}
