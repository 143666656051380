import {classNames} from '_metronic/helpers'

interface ReferralButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children: React.ReactNode
}

export function ReferralButton({className, children, ...rest}: ReferralButtonProps) {
  return (
    <button
      type='button'
      className={classNames(
        'rounded-[26px] flex flex-row gap-2.5 items-center justify-center shrink-0 relative overflow-hidden pt-3 pr-6 pb-3 pl-6',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
