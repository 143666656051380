/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useThemeMode} from '_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {IHeader, useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

const calculateStickyOffset = (header: IHeader): string => {
  if (header.fixed.desktop && header.fixed.tabletAndMobile) {
    return '{default: "200px", lg: "300px"}'
  }

  if (header.fixed.desktop && !header.fixed.tabletAndMobile) {
    return '{lg: "300px"}'
  }

  if (header.fixed.tabletAndMobile && !header.fixed.desktop) {
    return '{default: "200px", lg: false}'
  }

  return ''
}

const calculateShowSticky = (header: IHeader): boolean => {
  return (
    (header.fixed.desktop && header.fixed.tabletAndMobile) ||
    (header.fixed.desktop && !header.fixed.tabletAndMobile) ||
    (header.fixed.tabletAndMobile && !header.fixed.desktop)
  )
}

export function HeaderWrapper() {
  const {mode} = useThemeMode()
  const {config, classes, attributes} = useLayout()
  const {header} = config
  const [stickyOffset, setStickyOffset] = useState<string>(calculateStickyOffset(header))
  const [showSticky, setShowSticky] = useState<boolean>(calculateShowSticky(header))
  useEffect(() => {
    setStickyOffset(calculateStickyOffset(header))
    setShowSticky(calculateShowSticky(header))
  }, [header])

  return (
    <div
      id='kt_header'
      className={clsx('header my-4 lg:!my-0', classes.header.join(' '))}
      {...attributes.headerMenu}
      data-kt-sticky={showSticky ? 'true' : 'false'}
      data-kt-sticky-name='header'
      data-kt-sticky-offset={stickyOffset}
    >
      <div className='px-10 w-full flex flex-wrap justify-between gap-2' id='kt_header_container'>
        <DefaultTitle />

        {/* begin::Wrapper */}
        <div className='d-flex d-lg-none align-items-center ms-n2 me-2'>
          {/* begin::Aside mobile toggle */}
          <div className='btn btn-icon btn-active-icon-primary' id='kt_aside_toggle'>
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1 mt-1' />
          </div>
          {/* end::Aside mobile toggle */}

          {/* begin::Logo */}

          <Link to='/setup' className='mx-2 d-flex d-lg-none justify-content-center'>
            {mode === 'light' && (
              <img
                alt='Logo'
                className='h-40px logo theme-light-show'
                src={toAbsoluteUrl('/media/logos/horizontalLogo.svg')}
              />
            )}

            {mode === 'dark' && (
              <img
                alt='Logo'
                className='h-40px logo theme-dark-show'
                src={toAbsoluteUrl('/media/logos/light/gradientlogo.svg')}
              />
            )}
          </Link>
          {/* end::Logo */}
        </div>
        {/* end::Wrapper */}
        <Topbar />
      </div>
    </div>
  )
}
