/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {UserMenu} from '../user-menu/UserMenu'

const AsideUserMenu: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::Avatar */}
          <Link to='/account' className='symbol symbol-circle symbol-40px'>
            <img
              className='object-cover'
              src={currentUser?.image || toAbsoluteUrl('/media/avatars/blank.png')}
              alt='avatar'
            />
          </Link>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            <Link
              to='/account'
              className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1 text-capitalize'
            >
              {currentUser?.first_name && currentUser?.last_name ? (
                `${currentUser?.first_name} ${currentUser?.last_name}`
              ) : currentUser?.name ? (
                <span className='mb-2 lowercase text-gray-500 text-lg font-medium'>
                  @{currentUser?.name}
                </span>
              ) : (
                'No Name'
              )}
            </Link>
            <span className='mt-2 text-muted fw-bold d-block fs-7 lh-1 text-capitalize'>
              {currentUser?.followings} follower
            </span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  )
}

export {AsideUserMenu}
