/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  // const intl = useIntl()

  const environment = process.env.NODE_ENV

  return (
    <>
      <AsideMenuItem
        to='/setup/overview'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Setups Overview'
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/setup/add-setup'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Create A Setup'
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/referral'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Refer & Earn'
        fontIcon='bi-app-indicator'
      />

      {(environment === 'development' || environment === 'test') && (
        <AsideMenuItemWithSub
          to='/crafted/widgets'
          title='Widgets'
          icon='/media/icons/duotune/arrows/arr001.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}
    </>
  )
}
