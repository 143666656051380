/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {isLoginExpired} from '../../_metronic/helpers'
import {Theme, ToastContainer} from 'react-toastify'
import {useThemeMode} from '_metronic/partials/layout/theme-mode/ThemeModeProvider'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const {currentUser, logout, auth} = useAuth()
  const {mode} = useThemeMode()

  /*useEffect(() => {
    // if (auth?.access_token)
    isLoginExpired({
      token: auth?.access_token,
      logoutCb: () => {
        console.log("#isLoginExpired");
        return logout;
      },
    })
  }, [auth, logout])*/

  return (
    <>
      <ToastContainer
        position='top-right'
        className="'fs-5'"
        autoClose={4000}
        closeOnClick
        newestOnTop
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme={mode as Theme}
        limit={1}
      />
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {auth && currentUser ? (
              // && currentUser.is_change_password
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/setup' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export {AppRoutes}
