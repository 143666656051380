import {memo} from 'react'
import Modal from 'react-responsive-modal'

import {KTSVG, classNames} from '_metronic/helpers'

interface UpdateSetupProps {
  open: boolean
  className?: string
  onClose: () => void
  children: React.ReactNode
  title: string
}

export function UpdateSetup({open, onClose, className, children, title}: UpdateSetupProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: {
          background: 'var(--kt-card-bg)',
          borderRadius: '10px',
          padding: '0',
          margin: '0',
        },
      }}
      showCloseIcon={false}
    >
      <div className='px-5 py-2.5 border-bottom flex justify-between items-center'>
        <h2 className='text-capitalize text-lg font-medium'>{title}</h2>

        <KTSVG
          onClick={onClose}
          path='/media/icons/duotune/arrows/arr061.svg'
          className='p-2 btn btn-active-light-danger svg-icon svg-icon-2x'
        />
      </div>

      <div className={classNames('p-5 ', className)}>{children}</div>
    </Modal>
  )
}
