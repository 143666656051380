import {cn, toAbsoluteUrl} from '_metronic/helpers'

export function Loading({imgClass = '', className}: {imgClass?: string; className?: string}) {
  return (
    <div
      className={cn(
        'w-full h-full flex gap-3 flex-col items-center justify-center text-2xl font-bold text-center',
        className
      )}
    >
      <img
        className={cn('w-[220px] h-[150px]', imgClass)}
        src={toAbsoluteUrl('/media/logos/bottomUpVertical.svg')}
        alt='Metronic logo'
      />
      <h1>Loading ...</h1>
    </div>
  )
}
