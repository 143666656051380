import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {toast} from 'react-toastify'

import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, refreshToken, verifyToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {atom, useAtom} from 'jotai'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  checkLogin: () => Promise<void>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  checkLogin: () => Promise.resolve(),
}

export const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}
const authAtom = atom<AuthModel | undefined>(authHelper.getAuth())

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useAtom(authAtom)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  }

  // Sayfa yenilendiğinde veya requestler hata verdiğinde tetiklenir. Tüm HTTP isteği öncesinde veya useQuery catch methodu içerisine eklenmelidir.
  const checkLogin = async () => {
    const currRoute = new URL(window.location.href).pathname.split('/')[1]
    if (currRoute === 'auth') {
      return;
    }

    if (!auth) {
      logout()
      toast.error('Your session has expired. Please log in again.')
    }

    // token verify check edilir.
    const isTokenValid = await verifyToken(auth?.access_token || "");

    // token valid değilse refresh token akışı başlatılır.
    if (!isTokenValid) {
      // eski token ile birlikte refresh token apisine istek atılır.
      const authRefresh = await refreshToken({
        headers: {
          Authorization: auth?.refresh_token || '',
        },
      });

      // güncel token bilgisi context'e kaydedilir.
      saveAuth(authRefresh);

      // güncel token ile kullanıcı isteği atılır. Bu istek atılmadığında uuid hatası vermektedir.
      const user = await getUserByToken(authRefresh?.access_token);

      if (user) {
        // yeni token axios'a set edilir.
        authHelper.setupAxios(authRefresh?.access_token);
        setCurrentUser(user);
      }

    }

  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, checkLogin}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, setCurrentUser, checkLogin} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      await checkLogin()
      try {
        if (!didRequest.current) {
          const user = await getUserByToken(apiToken)
          if (user) {
            authHelper.setupAxios(apiToken)
            setCurrentUser(user)
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          // token kaynaklı hata alındığında, logout olmadan token refresh kurgusunu devreye sokabilmek için logout methodu kapatıldı.
          // logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      // token kaynaklı hata alındığında, logout olmadan token refresh kurgusunu devreye sokabilmek için logout methodu kapatıldı.
      // logout()
      setShowSplashScreen(false)
    }
  }, [])

  /*
  ihtiyaç olursa geri açılabilir.
  useEffect(() => {
    const authInterval = setInterval(async () => {
      await checkLogin()
    }, 1000 * 5);

    return () => clearInterval(authInterval)
  }, [auth])*/

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
