// import {useAuth} from '../core/Auth'

import {LoginForm} from './LoginForm'
// import {UpdatePasswordForm} from './UpdatePasswordForm'

export function LoginPage() {
  //   const {currentUser} = useAuth()

  //   return currentUser && !currentUser?.is_change_password ? <UpdatePasswordForm /> : <LoginForm />
  return <LoginForm />
}
