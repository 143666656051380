import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {setupAxios} from './AuthHelpers'
import {AuthModel, ForgotRequestModel, PasswordUpdateModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/trader/me`
export const LOGIN_URL = `${API_URL}/auth/signin`
export const REGISTER_URL = `${API_URL}/register`
export const PASSWORD_UPDATE_URL = `${API_URL}/auth/password_update`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/send_password_reset_email`
export const CONFIRM_PASS_RESET_URL = `${API_URL}/auth/verify_password_reset_code`
export const VERIFY_TOKEN_URL = `${API_URL}/auth/verify_token`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`

// Server should return AuthModel
export async function login(
  username?: string,
  password?: string
): Promise<{auth: AuthModel; user: UserModel}> {
  const {data: auth} = await axios.post<AuthModel>(
    LOGIN_URL,
    {
      username,
      password,
    },
    {
      headers: {
        Authorization: '',
      },
    }
  )
  setupAxios(auth.access_token)

  const {data: user} = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: auth.access_token,
    },
  })

  const followings = await (await axios.get(`${API_URL}/trader/me/followers`)).data.total_count

  return {
    auth: {
      ...auth,
    },
    user: {
      ...user,
      followings,
    },
  }
}

export const getFollowings = async () => {
  return await (
    await axios.get(`${API_URL}/trader/me/followers`)
  ).data.total_count
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<ForgotRequestModel>(REQUEST_PASSWORD_URL, {
    params: {
      email,
    },
  })
}

export const confirmPasswordReset = (reset_code: string, new_password: string) => {
  return axios.post<string>(CONFIRM_PASS_RESET_URL, {
    reset_code,
    new_password,
  })
}

export async function getUserByToken(token: string) {
  const {data: user} = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: token,
    },
  })

  const followings = await (
    await axios.get(`${API_URL}/trader/me/followers`, {
      headers: {
        Authorization: token,
      },
    })
  ).data

  return {
    ...user,
    followings: followings.total_count,
  }
}

export async function getUserFollowers(token: string) {
  return axios.get(`${API_URL}/trader/me/followers`, {
    headers: {
      Authorization: token,
    },
  })
}

export async function passwordUpdate(password: string, password_verify: string) {
  return axios.post<PasswordUpdateModel>(PASSWORD_UPDATE_URL, {
    password,
    password_verify,
  })
}

export const verifyToken = async (token: string): Promise<boolean> => {
  try {
    const {data} = await axios.post<boolean>(VERIFY_TOKEN_URL, null, {
      headers: {
        token,
        Authorization: token,
      },
    })

    return data
  } catch (error:any) {
    // status code 401 olduğunda da false dönmesini beklediğimiz için direkt false tanımlandı.
    return false;

  }
}

export const refreshToken = async (config: AxiosRequestConfig): Promise<AuthModel> => {
  const {data: auth} = await axios.post<AuthModel>(REFRESH_TOKEN_URL, null, config)
  setupAxios(auth.access_token);

  return {
    ...auth
  }
}
