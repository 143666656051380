import React, {useMemo} from 'react'

export const useVariable = <T>(factory: () => T, deps: React.DependencyList = []) =>
  useMemo(factory, deps ? deps : [])

export const useDisclose = (initialState = false) => {
  const [isOpen, setIsOpen] = React.useState(initialState)
  const open = React.useCallback(() => setIsOpen(true), [])
  const close = React.useCallback(() => setIsOpen(false), [])
  const toggle = React.useCallback(() => setIsOpen(!isOpen), [isOpen])
  return {isOpen, open, close, toggle}
}
