import {useRef} from 'react'
import {RangeKeyDict} from 'react-date-range'
import {DateRangePicker, Range} from 'react-date-range'
import {AiFillCalendar} from 'react-icons/ai'

import {classNames} from '_metronic/helpers'
import {useDetectOutside, useDisclose} from '_metronic/hooks'

interface DateInputProps
  extends Omit<React.ComponentProps<typeof DateRangePicker>, 'ranges' | 'onChange'> {
  className?: string
  containerClass?: string
  ranges: Range
  onChange: (rangesByKey: RangeKeyDict) => void
}

export function DateInput({ranges, className, containerClass, onChange, ...rest}: DateInputProps) {
  const pickerRef = useRef(null)

  const startDate = ranges?.startDate?.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const endDate = ranges?.endDate?.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const {toggle, close, isOpen} = useDisclose()

  useDetectOutside({
    cb: close,
    ref: pickerRef,
  })

  return (
    <div ref={pickerRef} className={classNames('relative', containerClass)}>
      <div className='w-full flex items-center justify-between overflow-hidden'>
        <button
          onClick={toggle}
          className='whitespace-nowrap flex gap-2 items-center justify-between w-full px-6 py-3 border border-gray-300 bg-white dark:!bg-mydarkbg text-gray-700 font-medium text-lg rounded-full'
        >
          {startDate} - {endDate}
          <AiFillCalendar size={20} />
        </button>
      </div>

      <div
        className={classNames(
          'whitespace-nowrap absolute top-16 !rounded-lg overflow-hidden !transition-all duration-200 ease-in-out z-40 shadow-md shadow-[rgba(255,255,255,0.5]',
          isOpen ? 'block md:!visible !opacity-100' : 'hidden md:block md:!invisible !opacity-0',
          className
        )}
      >
        <DateRangePicker
          ranges={[ranges]}
          onChange={onChange}
          showPreview={true}
          moveRangeOnFirstSelection={false}
          direction='horizontal'
          editableDateInputs
          {...rest}
        />
      </div>
    </div>
  )
}
