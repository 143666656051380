import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'

import {apiURL} from '_metronic/helpers'

export type InvitationType = {
  trader_id: string
  subscription_id: string
  amount: number
  installments: number | null
  installment: number | null
  trader_earn_date: string
  paid_date: string | null
  is_paid: boolean
  is_cancelled: boolean
  is_withdrawal: boolean
  product_name: string
  start_date: string
  expire_date: string
  is_expired: boolean
  is_trial: boolean
  user_name: string
}

export type DayFilter = 'all' | 'last7' | 'last30'

export const getReferralCode = async (): Promise<
  AxiosResponse<{
    code: string
  }>
> => axios.get(apiURL('/trader/me/referral_code'))

export const getInvitationList = async (
  params?: {
    skip?: number
    limit?: number
    from_date?: string
    to_date?: string
    order_by?: string
  },
  config?: AxiosRequestConfig
): Promise<
  AxiosResponse<{
    total_count: number
    start: number
    limit: number
    data: InvitationType[]
  }>
> => axios.get(apiURL('/trader/me/invitations'), {params, ...config})

export const getReferralSummary = async (
  day_filter: DayFilter
): Promise<
  AxiosResponse<{
    total_amount: number
    total_user: number
  }>
> =>
  axios.get(apiURL('/trader/me/invitations/summary'), {
    params: {
      day_filter,
    },
  })
