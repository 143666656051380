import * as yup from 'yup'

export const setPasswordModel = {
  initials: {
    password: '',
    confirm_password: '',
  },
  schema: yup.object().shape({
    password: yup.string().required('Password is required'),
    confirm_password: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
}

export const loginModel = {
  initials: {
    email: '',
    password: '',
  },
  schema: yup.object().shape({
    email: yup
      .string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: yup
      .string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  }),
}

export const passwordUpdateModel = {
  initials: {
    password: '',
    password_verify: '',
  },
  schema: yup.object().shape({
    password: yup.string().min(6).required('Password is required'),
    password_verify: yup
      .string()
      .min(6)
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
}

export const forgotPasswordModel = {
  initials: {
    email: '',
  },
  schema: yup.object().shape({
    email: yup.string().email('Wrong email format').required('Email is required'),
  }),
}

export const forgotPasswordConfirmModel = {
  initials: {
    reset_code: '',
    new_password: '',
    confirm_password: '',
  },
  schema: yup.object().shape({
    reset_code: yup.string().required('Reset code is required'),
    new_password: yup.string().required('New password is required'),
    confirm_password: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
  }),
}
