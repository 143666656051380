import React from 'react'
import {classNames} from '../../../helpers'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string
  label?: string
  placeholder?: string
  labelClass?: string
  className?: string
  containerClass?: string
  required?: boolean
  inputTouched?: boolean
  inputError?: string
  ref?: React.RefObject<HTMLInputElement>
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      className,
      label,
      containerClass,
      labelClass,
      placeholder,
      required,
      inputTouched,
      inputError,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={containerClass}>
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              `text-lg font-bold mb-2 `,
              required ? 'required' : '',
              labelClass
            )}
          >
            {label}
          </label>
        )}

        <input
          ref={ref}
          className={classNames(
            `form-control safari-only:text-s-md`,
            !!inputTouched && !!inputError ? 'is-invalid' : '',
            className
          )}
          id={id}
          placeholder={placeholder}
          required={required}
          {...rest}
        />
        {!!inputTouched && !!inputError && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{inputError}</div>
          </div>
        )}
      </div>
    )
  }
)
