import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'

const DashboardPage = () => (
  <>
    {/* <div className='row gy-5 g-xl-10'>
      <div className='col-xl-4'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
        />
      </div>

      <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
        />
      </div>

      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div>
    </div> */}

    <TablesWidget9
      className='mb-5 mb-xl-10'
      cols={['ID', 'Name', 'Email']}
      rows={[
        {
          id: 1,
          name: 'Paul',
          email: 'test@gmail.com',
          phone: '1234567890',
          status: 'Active',
        },
        {
          id: 2,
          name: 'Paul',
          email: 'test@gmail.com',
          phone: '1234567890',
          status: 'Active',
        },
      ]}
    />

    <ul className='pagination'>
      <li className='page-item previous'>
        <a href='#' className='page-link'>
          <i className='previous'></i>
        </a>
      </li>

      <li className='page-item active'>
        <a href='#' className='page-link'>
          1
        </a>
      </li>

      <li className='page-item'>
        <a href='#' className='page-link'>
          2
        </a>
      </li>

      <li className='page-item'>
        <a href='#' className='page-link'>
          3
        </a>
      </li>

      <li className='page-item'>
        <a href='#' className='page-link'>
          4
        </a>
      </li>

      <li className='page-item'>
        <a href='#' className='page-link'>
          5
        </a>
      </li>

      <li className='page-item'>
        <a href='#' className='page-link'>
          6
        </a>
      </li>

      <li className='page-item next'>
        <a href='#' className='page-link'>
          <i className='next'></i>
        </a>
      </li>
    </ul>

    {/* <div className='row gy-5 g-xl-10'>
      <div className='col-xxl-6'>
        <ListsWidget5 className='card-xl-stretch mb-xl-10' />
      </div>

      <div className='col-xxl-6'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />
      </div>
    </div> */}
  </>
)

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle description='You’ve got 24 New Sales' breadcrumbs={[]}>
        Hello,Paul
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
