/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'

import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {loginModel} from '../form/models'
import {LoginModel} from '../core/_models'

import {Input} from '_metronic/layout/components/Forms/Input'

export function LoginForm() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const {mutate} = useMutation('login', async (values: LoginModel) => {
    const res = await login(values.email, values.password)
    return res
  })

  const formik = useFormik({
    initialValues: loginModel.initials,
    validationSchema: loginModel.schema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      mutate(values, {
        onSuccess: ({auth, user}) => {
          saveAuth(auth)
          setCurrentUser(user)
          toast(`Welcome ${user.name || 'User'}👋!`)
        },
        onError: (error) => {
          const {response} = error as AxiosError
          const errMsg = response?.data?.message || 'The login detail is incorrect'
          toast(errMsg, {type: 'error'})
          saveAuth(undefined)
          setStatus(errMsg)
        },
        onSettled: () => {
          setSubmitting(false)
          setLoading(false)
        },
      })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Form group */}
      {formik.status ? (
        <div className='mb-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Please use your given credentials to login</div>
        </div>
      )}

      <Input
        containerClass='fv-row mb-10'
        id='email'
        type='email'
        required
        {...formik.getFieldProps('email')}
        label='Email'
        placeholder='Type your email'
        inputTouched={formik.touched.email}
        inputError={formik.errors.email}
      />

      <Input
        containerClass='fv-row mb-10'
        id='password'
        type='password'
        required
        {...formik.getFieldProps('password')}
        label='Password'
        placeholder='Type your password'
        inputTouched={formik.touched.password}
        inputError={formik.errors.password}
      />

      <div className='d-flex justify-content-between my-3'>
        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary fs-6 fw-bolder'>
          Forgot Password?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <button
        type='submit'
        id='kt_sign_in_submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        disabled={formik.isSubmitting || !formik.isValid || loading}
      >
        {!loading && <span className='indicator-label'>Continue</span>}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      {/* end::Action */}
    </form>
  )
}
